@import '../../styles/mixins';

.device-advanced-graph-edit {
  h3{
    font-size: 2rem;
    margin-top: 35px;
    border-bottom: 1px solid var(--color-border-widget-header-main);
    padding-bottom: 5px;
    margin-bottom: 25px;
  }
  .option{
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    a.opt{
      font-size: 20px;
      color: #98a3ae;
      padding: 2px 7px 2px 7px;
      &.active{
        color: @blue;
        &.line{
          background-image: url(../../assets/graphs-blue.svg);
        }
      }
      &.line{
        margin-bottom: 0;
        background: url(../../assets/graphs.svg) no-repeat;
        background-size: 95%;
        background-position: 0px 5px;
        display: inline-block;
        width: 25px;
        height: 25px;
      }
    }
  }
  .common-tags{
    display: flex;
    flex-wrap: wrap;
    > * {
      flex: 1;
    }
    .option{
      padding-bottom: 0;
      padding-right: 0;
    }
  }
  &.advanced .common-tags > span .x,
  a.delete{
    background: url(../../assets/plus.svg) no-repeat;
    background-size: 100%;
    filter: grayscale(100%);
    transform: rotate(-405deg);
    opacity: 0.8;
  }
  &.advanced{
    .common-tags > span.tag{
      max-width: 300px;
      position: relative;
      padding: 10px;
      .label{
        font-weight: bold;
      }
      .x{
        color: transparent;
        position: absolute;
        right: -8px;
        top: -8px;
      }

    }
    &.multi-param{
      display: flex;
      flex-direction: column;
      .line.params{
        order: 1;
      }
      h4.stations{
        display: none;
      }
    }
    &.multi-device{
      display: flex;
      flex-direction: column;
      .line.params{
        margin-top: 15px;
        margin-bottom: 20px;
        order: -1;
      }
      .line.stations{
        order: 1;
      }
      h4.stations{
        margin-bottom: 0;
      }
      h4.params{
        display: none;
      }
    }

    // Custom date range styles
    .line.custom-date-range {
      margin-top: 25px;
      padding-top: 15px;
      border-top: 1px solid var(--color-border-widget-header-main);
      order: 2;
      
      h4 {
        margin-bottom: 15px;
      }
      
      .custom-date-option {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        
        label {
          margin-right: 15px;
          font-weight: normal;
          flex: 1;
        }
      }
    }
  }
  
  .component-device-chooser .header-wrap a.header{
    position: relative;
    color: transparent;
    &::before{
      content: 'Choose Stations';
      position: absolute;
      left: 0px;
      top: 7px;
      color: var(--color-text-main);
    }
  }
}
